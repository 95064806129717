<template lang="pug">
  v-container
    v-row(v-if="hasLoaded")
      v-col
        .details.mb-10
          v-layout(
            align-center
          )
            h1.text-h4 {{ shop.name }}
            v-col.d-flex
              .actions.ml-auto
                v-chip.mr-2(
                  v-if="user.isAdmin"
                  :color="shop.status === 'ACTIVE' ? 'primary' : 'grey lighten-1'"
                  small
                ) {{ shop.status }}
                v-tooltip(
                  top
                  v-if="user.isAdmin || user.isRegionAdmin"
                )
                  template(#activator="{ on, attrs}")
                    v-btn.px-0.mr-2.my-1(
                      v-on="on"
                      min-width="40px"
                      @click="deleteShop"
                      color="error"
                    )
                      v-icon mdi-delete
                  span Delete Shop
                v-btn(
                  :to="{ name: 'edit-shop', params: { shopId: shop.id }}"
                  color="primary"
                  v-if="user.isAdmin || user.isRegionAdmin || user.isShopAdmin"
                )
                  v-icon(
                    left
                  ) mdi-pencil
                  | Edit Shop
          h2.subtitle-1 {{ shop.address1 }}
            br
            | {{ shop.city }}, {{ shop.state }} {{ shop.zip }}
          v-row.d-flex.flex-column.py-0(
            v-if="this.$route.meta.title === 'Shop Invoices'"
          )
            Dialog.px-0.mb-3.ml-3.mt-3(
              :verbiage="'How to make an invoice'"
              :video="'https://www.youtube.com/embed/ho74q0F4L8o'"
            )
            v-btn.ml-3(
              outlined
              color="primary"
              :href="`/pdf/CostRecoverySheet1.pdf`"
              target="_blank"
              style="max-width: max-content;"
              class="media-btn"
            ) Cost Recovery Sheet 1
            v-btn.mt-3.ml-3(
              outlined
              color="primary"
              :href="`/pdf/CostRecoverySheet2.pdf`"
              target="_blank"
              style="max-width: max-content;"
              class="media-btn"
            ) Cost Recovery Sheet 2
          v-row.d-flex.flex-column.py-0(
            v-if="this.$route.meta.title === 'Shop Orders'"
          )
            Dialog.px-0.mb-3.ml-3.mt-3(
              :verbiage="'How to make an order'"
              :video="'https://www.youtube.com/embed/4vD4Wb3ypRA'"
              class="media-btn"
            )
            v-btn.ml-3(
              outlined
              color="primary"
              :href="`/pdf/OrderingSheet1.pdf`"
              target="_blank"
              style="max-width: max-content;"
              class="media-btn"
            ) Ordering Sheet 1
            v-btn.mt-3.ml-3(
              outlined
              color="primary"
              :href="`/pdf/OrderingSheet2.pdf`"
              target="_blank"
              style="max-width: max-content; border-color: secondary;"
              class="media-btn"
            ) Ordering Sheet 2
        v-layout(
          flex
          justify-space-around
          v-if="isInvoicesPage"
        )
        v-row.mb-10.ml-1.monthlyTotals
          h4(
          ) Previous Monthly Total: ${{ monthlyTotal.previousMonthTotal.toFixed(2) }}
          h4(
          ) Current Monthly Total: ${{ monthlyTotal.currentMonthTotal.toFixed(2) }}
        v-tabs(
          v-if="user.isAdmin || user.isRegionAdmin"
        )
          v-tab(
            :to="{ name: 'shop' }"
            v-if="shop.usesInvoicing"
          ) Invoices
          v-tab(
            :to="{ name: 'shop-orders' }"
            v-if="shop.usesOrdering"
          ) Orders
          v-tab(
            :to="{ name: 'shop-users' }"
          ) Users
          v-tab(
            :to="{ name: 'shop-products' }"
          ) Products
          v-tab(
            :to="{ name: 'shop-techs' }"
          ) Techs
        router-view
        v-layout(flex flex-column font="primary")
          h4.mx-auto.font-weight-thin.disclaimer **All published pricing is Suggested Cost and Suggested List Pricing for Cost Recovery Purposes**
          h4.mx-auto.font-weight-thin.disclaimer *Pricing is not reflective of actual cost from ASP Clips or its Distributors*
</template>

<script>
import { useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import InvoicesTable from '@/components/tables/InvoicesTable'
import ProductsTable from '@/components/tables/ProductsTable'
import UsersTable from '@/components/tables/UsersTable'
import TechsTable from '@/components/tables/TechsTable'
import Dialog from '@/components/Dialog'

export default {
  name: 'Shop',
  components: {
    InvoicesTable,
    ProductsTable,
    UsersTable,
    TechsTable,
    Dialog
  },
  props: {
    shopId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const { Shop } = context.root.$FeathersVuex.api
    const { MonthlyTotal } = context.root.$FeathersVuex.api
    // Include this so we can join the region
    const params = computed(() => {
      return { query: { $eager: 'region' } }
    })

    const { item: shop, hasLoaded } = useGet({
      model: Shop,
      params,
      id: props.shopId
    })

    const { item: monthlyTotal } = useGet({
      model: MonthlyTotal,
      params,
      id: props.shopId
    })

    return {
      shop,
      hasLoaded,
      monthlyTotal
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    isInvoicesPage () {
      return this.$route.name.includes('invoices')
    }
  },
  methods: {
    async deleteShop () {
      try {
        const message = 'Are you sure you want to delete this shop?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        if (isYes) {
          await this.shop.remove()

          this.$snackSuccess('Shop Deleted')

          if (this.user.isAdmin) {
            this.$router.push({ name: 'shops' })
          } else if (this.user.isRegionAdmin) {
            this.$router.push({ name: 'region', params: { regionId: this.user.region_id } })
          }
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .disclaimer {
    font-family: 'Hemi';
  }
  .media-btn.v-btn--outlined {
    border: 1px solid #f5780c;
  }
  .monthlyTotals {
    justify-content: space-around;
  }

</style>
